<script>
import { Pie } from "vue-chartjs";
import { readGraphData } from '@/store/main/getters';
import { dispatchGetGraphData } from '@/store/main/actions';

export default {
  extends: Pie,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      gradient: null,
      gradient2: null,
      graphData: null,
      tickers: null,
      values: null,
    };
  },
  async mounted() {
    await dispatchGetGraphData(this.$store, { plan_name: "normal_plan_tokens" });
      const graphs = readGraphData(this.$store);
      if (graphs) {
        this.tickers = graphs.tickers;
        this.values = graphs.values;
        // this.tickers = [34.5, 32.5, 9.5, 7.5, 7, 5, 2.5,];
        // this.values = [34.5, 32.5, 9.5, 7.5, 7, 5, 2.5,];
        
      }

    // this.gradient = this.$refs.canvas
    //   .getContext("2d")
    //   .createLinearGradient(0, 0, 0, 450);
    // this.gradient2 = this.$refs.canvas
    //   .getContext("2d")
    //   .createLinearGradient(0, 0, 0, 450);

    // this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
    // this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
    // this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

    // this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");
    // this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
    // this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");
    this.renderChart(
      {
        // labels: ["BSC", "Solana", "Terra", "ETH", "Matrixport", "Binance", "FTX"],
        labels: this.tickers,
        datasets: [
          {
            backgroundColor: [
              'rgba(75, 192, 192, 0.2)', // green
              'rgba(54, 162, 235, 0.2)', // blue
              'rgba(255, 205, 86, 0.2)', // yellow
              'rgba(153, 102, 255, 0.2)', // purple              
              'rgba(255, 99, 132, 0.2)', // red
              'rgba(255, 159, 64, 0.2)', // orange
              'rgba(201, 203, 207, 0.2)', // gray
            ],
            borderColor: [
              'rgb(75, 192, 192)', // green
              'rgb(54, 162, 235)', // blue
              'rgb(255, 205, 86)', // yellow
              'rgb(153, 102, 255)', // purple
              'rgb(255, 99, 132)', // red
              'rgb(255, 159, 64)', // orange
              'rgb(201, 203, 207)', // gray
            ],
            // data: [34.5, 32.5, 9.5, 7.5, 7, 5, 2.5,]
            data: this.values
          }
        ]
      },
      { 
        "legend": {
          "display": true,
          // "labels": {
          //     "fontSize": 20,
          // },
          "onClick": null,
        },
        responsive: true, 
        maintainAspectRatio: false,
        // title: {
        //   display: true,
        //   text: '歷史利率',
        //   fontSize: 14,
        //   fontColor: "#AAAAAA",
        // },
      }
    );
  }
};
</script>